<template>
  <div class="app-main-content">
    <card title="报表" class="report-group">
      <div class="tab-item all" :class="{active: activeTab === ''}">
        <span class="text">所有表格</span>
        <span class="num"></span>
      </div>
<!--      <div class="tab-action">-->
<!--        分组-->
<!--      </div>-->
<!--      <div class="tab-item-list">-->
<!--        <div-->
<!--            class="tab-item"-->
<!--            v-for="item in report_menu_list"-->
<!--            :key="item.cat_code"-->
<!--            :class="{active: activeTab === item.cat_code}"-->
<!--            @click="changeTab(item)">-->
<!--          <span class="text">{{item.cat_name}}</span>-->
<!--        </div>-->
<!--      </div>-->
    </card>
    <card class="report-list-card">
      <template #title>
        <span class="list-title">类型</span>
      </template>
      <div class="report-list">
        <a-table :data-source="report_form_type_list" rowKey="id" :pagination="false" :customRow="(record) => customRow(record, activeTab)">
          <a-table-column data-index="title" title="全部">
            <template #default="t">
              {{t}}
            </template>
          </a-table-column>
        </a-table>
      </div>
    </card>
  </div>
</template>

<script>

export default {
  name: 'reportForm',
  data() {
    return {
      activeTab: "",
      report_menu: [],
      report_form_type: [],
      report_form_type_list: [{
        id: '1',
        title: '概览报表',
        path: '/reportForm/overview'
      },{
        id: '2',
        title: '里程碑报表',
        path: '/reportForm/milestone'
      },{
        id: '3',
        title: '任务报表',
        path: '/reportForm/task'
      },{
        id: '4',
        title: '工时报表',
        path: '/reportForm/workTimeLog'
      },{
        id: '5',
        title: '阶段报表',
        path: '/reportForm/stage'
      }]
    }
  },
  computed: {

  },
  created() {
    console.log(111111111)
  },
  mounted() {
  },
  methods: {
    customRow(record) {
      return {
        on: {
          click: () => this.clickRow(record)
        }
      }
    },
    clickRow(row) {
      let {href} = this.$router.resolve({
        path: row.path,
        query: {}
      })
      location.href = href
    }
  }
}
</script>

<style lang="less" scoped>
.app-main-content {
  display: flex;
  justify-content: space-between;
  .report-group {
    box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);
    width: 240px;
    flex-shrink: 0;
    .tab-item {
      padding: 0 20px;
      height: 38px;
      font-weight: 500;
      line-height: 38px;
      color: #4D4D4D;
      font-size: 14px;
      &.active {
        background-color: fadeout(@primary-color, 90%);
        color: var(--primary-color);
      }
      &.all {
        font-size: 16px;

        .num {
          font-size: 16px;
        }
      }
      .num {
        color: #A7A7A7;
        margin-left: 3px;
      }

    }
    .tab-item-list .tab-item {
      font-weight: 400;
    }
    .tab-action {
      padding: 0 20px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      border-top: 1px solid #EDEDED;
      border-bottom: 1px solid #EDEDED;
      .btn {
        padding: 0 10px;
      }
    }
  }
  /deep/.edp-card-body {
    overflow: hidden;
  }
  .report-list-card {
    flex: 1;
    margin-left: 20px;
    box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);
    .report-list {
      width: 100%;
      height: 100%;
    }
    .list-title {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #3B3B3B;
      .num {
        color: #A7A7A7;
        margin-left: 5px;
      }
    }
  }
}
</style>